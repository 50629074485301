import instance from "../Services";
import Swal from "sweetalert2";
import moment from "moment/moment";
import CryptoJS from "crypto-js";
import * as XLSX from "xlsx";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Experience from "../Components/PracticeManagement/Main/Masters/Physician/PhysicianOnBoarding/PhysicianEducationTabs/Experience";
import { values } from "lodash-es";
import botInstance from "../Services/BotIndex";

// Const List
export const genderList = [{ name: "Male" }, { name: "Female" }, { name: "Other" }];
export const roles = () => {
  switch (getUserfromSS()?.roleId) {
    case 1:
      return [
        { roleName: "Organization Admin", roleId: "2" },
        { roleName: "Clinician", roleId: "4" },
      ];
    case 2:
      return [
        { roleName: "Hospital Admin", roleId: "3" },
        { roleName: "Clinician", roleId: "4" },
        { roleName: "Patient", roleId: "5" },
      ];
    case 3:
      return [
        { roleName: "Clinician", roleId: "4" },
        { roleName: "Patient", roleId: "5" },
      ];
    default:
      return [];
  }
};

export const rolesList = [
  { roleName: "Super Admin", roleId: 1 },
  { roleName: "Organization Admin", roleId: 2 },
  { roleName: "Hospital Admin", roleId: 3 },
  { roleName: "Clinician", roleId: 4 },
];
//Const

export const buttonColor = (status) => {
  switch (status) {
    case "All":
      return css.all;
    case "Submitted":
      return css?.Submitted;
    case "Pending":
      return css.pending;
    case "Approve":
    case "Completed":
      return css.approved;
    case "Rejected":
      return css.reject;
    case "Not Submitted":
      return css.cancel;
    default:
      return "defaultColor";
  }
};
export const css = {
  themeColor: "#154360",
  textColor: "#FFFFFF",
  themeColor2: "#4d6c80",
  lightColor: "#b4c9d6",
  newTheam: "#154360",
  pending: "#FCF2D5",
  green: "#80bdb2",
  red: "#db767a",
  headericonSize: "1.5rem",
  iconSize1: "1rem",
  iconSize2: "1.1rem",
  Request: "#ECE9FE",
  Booked: "#C5F6E2",
  // Booked:"#d8EFFF",
  Preadmission: "#d8EFFF",
  Preadmission2: "#fff",
  Collected: "#D1B3FF",
  Admitted: "#fae3ca",
  Pending: "#000",
  Complete: "#80bdb2",
  thickBlue: "#03469E",
  all: "#935A91",
  approved: "#1EC198",
  pending: "#F7CB74",
  reject: "#D66B8C",
  cancel: "#FF8C78",
  Submitted: "#007bff",
};

export const encryptKey = "1M3D^ERZ@B@!TN@1";
export const ivKey = "1B@!TN@@M3D^ERZ1";

export const mirrorFlyLicenseKey = "9fZz6xlc6Ahvk2SgzH9DsynpcFSENb";
export const twilioTokenUrl = "https://vmsapi.hitechhealth.us/api/";
export const SCUrl = "https://baithna.tribandtech.com:7075/";
export const googleMapKey = "AIzaSyB8hjaTbAN8rRehBEvtjT8OO0hBY5_2-C4";
export const baseUrl = process.env.REACT_APP_API_URL;
export const basefileURL = process.env.REACT_APP_API_ASSETS_URL;
export const jitsiMeetUrl = "https://video.tribandtech.com:8443/anatrx/"
// https://quboto.tribandtech.com:8000/get_cpt_codes
export const AIURL = "https://quboto.tribandtech.com:8000/";

// export const AIURL = "http://128.199.22.163:8000/";
export const QUBOTO = "https://baithnabot.tribandtech.com:4567/";

export const urls = {
  ICD_AM_10: "ICD_AM_10",
  nhs: {
    getPatient: "NHS/Patient",
  },
  get_bot_response: "get_bot_response",

  auth: {
    signIn: "Account/SignIn",
  },
  bot: {
    bot: "bot_response",
    icd10: "get_icd_codes",
    procedure: "get_procedure_codes",
    diagnosis: "get_diagnosis_codes",
    cpt: "get_cpt_codes",
    getScrore: ""
  },
  Inpatient: {
    SaveAdmission: "Inpatient/SaveAdmission",
    GetAdmission: "Inpatient/GetAdmission",
    SaveProcedure: "Inpatient/SaveProcedure",
    GetProcedures: "Inpatient/GetProcedures",
    SaveIPVitals: "Inpatient/SaveIPVitals",
    GetIPVitals: "Inpatient/GetIPVitals",
    SaveInpatientProcedure:"Inpatient/SaveInpatientProcedure",
    GetInpatientProcedure:"Inpatient/GetInpatientProcedure"
  },
  Billing:{
    GetPharmaBill :"Billing/GetPharmaBill",
    GetLabBill:"Billing/GetLabBill",
    GetAdmissionBill:"Billing/GetAdmissionBill",
    UpdateBillingStatus:"Billing/UpdateBillingStatus",
    UpdateClaimStatus:"Billing/UpdateClaimStatus",
    GetBilling:"Billing/GetBilling"

  },
  
  Organization: {
    CreateAccount: "Organization/CreateAccount",
    GetAllOrganization: "Organization/GetAllOrganization",
    UpdateOrganization: "Organization/UpdateOrganization",
    MedicalLicense: "Organization/MedicalLicense",
    FinalSubmit: "Organization/FinalSubmit",
    GetAllMedicalLicense: "Organization/GetAllMedicalLicense",
    UpdateStatus: "Organization/UpdateStatus",
    AssignModule: "Organization/AssignModule",
    GetAllNotifications: "Organization/GetAllNotifications",
  },
  Portfolio: {
    Save: "Service/CreatePortfolio",
    GetAll: "Service/GetPortfolio",
    uploadImage: "Service/PortfolioImage",

  },



  Insurance: {
    SaveInsurance: "Insurance/SaveInsuranceProvider",
    GetAllProviders: "Insurance/GetAllInsuranceProviders",
    saveInsurancePlan:"Insurance/SaveInsurancePlan",
    GetInsurancePlan:"Insurance/GetAllInsurancePlans",
    GetAllInsurancePlanById:"Insurance/GetInsurancePlanById"
  },






  Service: {
    CreateService: "Service/CreateService",
    GetService: "Service/GetService",
    uploadImage: "Service/ServiceImage",
    SaveAppointment: "Appointment/SaveAppointment",
    GetAppointment: "Appointment/GetAppointment",
    SaveAppointmentDetails: "Appointment/SaveAppointmentDetails",
    GetAppointmentDetailsById: "Appointment/GetAppointmentDetailsById",
    UpdateStatus: "Appointment/UpdateStatus",
    GetForms: "Service/GetForms",
    GetFormPack: "Service/GetFormPack",
    FormPackSave: "Service/FormPackSave",
    FormSave: "Service/FormSave",
    SaveWard: "Service/SaveWard",
    GetWardCategory: "Service/GetWardCategory",
    GetWard: "Service/GetWard",
    SavePortfolio: "Service/AssignPortfolio",
    GetOrgPortfolio: "Service/GetOrganizationPortfolios",
    SaveLab: "Service/AssignLab",
    GetOrgLab: "Service/GetOrganizationLab",
    GetPortfolioOrganization: "Service/GetPortfolioOrganization"
  },
  Meeting: {
    save: "Meeting/SaveMeeting",
    getAll: "Meeting/GetMeetings",
    CancelMeetings: "Meeting/CancelMeetings"
  },
  Dashboard: {
    GetDashBoard: "User/GetDashBoard",
    GetTodayAppointments: "User/GetTodayAppointments"
  },

  Appointment: {
    GetAppointmentByService: "Appointment/GetAppointmentByService"
  },


  Clinician: {
    Save: "Clinician/Save",
    GetClinician: "Clinician/GetClinician",
    WorkExperienceTraining: "Clinician/WorkExperienceTraining",
    GetWorkExperienceTraining: "Clinician/GetWorkExperienceTraining",
    Education: "Clinician/Education",
    GetEducation: "Clinician/GetEducation",
    UpdateStatus: "Clinician/UpdateStatus",
    SheduleAvailability: "Clinician/ScheduleAvailability",
    GetAvailability: "Clinician/GetAvailability",
    GetAvailabilityWithAppointment: "Clinician/GetAvailabilityWithAppointment",
    GetAvailabilityClinicians: "Clinician/GetAvailabilityClinicians",
    ScheduleStatusUpdate: "Clinician/ScheduleStatusUpdate",
    GetClinicianById: "Clinician/GetClinicianById"
  },

  Lab: {
    SaveLabProvider: "Lab/SaveLabProvider",
    GetAllLabProviders: "Lab/GetAllLabProviders",
    SaveLab: "Lab/SaveLab",
    GetAllLab: "Lab/GetAllLab",
    SaveLabTest: "Lab/SaveLabTest",
    GetAllLabTestsById: "Lab/GetAllLabTestsById",
    SaveTestGroup: "Lab/SaveTestGroup",
    GetAllTests: "Lab/GetAllTests",
    GetAllTestGroup: "Lab/GetAllTestGroup",
    GetAllRoles: "Lab/GetAllRoles",
    LabClinicianSave: "Lab/LabClinicianSave",
    GetLabClinician: "Lab/GetLabClinician",
    SaveEducation: "Lab/SaveEducation",
    SaveWorkExperienceTraining: "Lab/SaveWorkExperienceTraining",
    GetLabEducation: "Lab/GetLabEducation",
    GetLabWorkExperienceTraining: "Lab/GetLabWorkExperienceTraining",
    SavePackage: "Lab/SavePackage",
    GetLabPackage: "Lab/GetLabPackage",
    SaveLabAppointment: "Lab/SaveLabAppointment",
    GetLabAppointment: "Lab/GetLabAppointment",
    GetAllSpecimenCollections: "Lab/GetAllSpecimenCollections",
    GetSpecimendataCollection: "Lab/GetSpecimendataCollection",
    GetAllSpecimenCategory: "Lab/GetAllSpecimenCategory",
    GetAllBodySites: "Lab/GetAllBodySites",
    SaveSpecimenData: "Lab/SaveSpecimenData",
    GetAllSpecimenData: "Lab/GetAllSpecimenData",
    UpdateLabAppointmentStatus: "Lab/UpdateLabAppointmentStatus",
    uploadImage: "Lab/UploadLabDoc",
    UpdateFinalStatus: "Lab/UpdateFinalStatus",
    SaveOrgLab: "Lab/SaveOrgLab",
    GetAllOrgLab: "Lab/GetAllOrgLab"
  },

  UserAccess: {
    Modules: "UserAccess/Modules",
    GetAccessLinks: "UserAccess/GetAccessLinks",
  },

  Manufacturar: {
    Save: "Pharma/SaveManufacturer",
    getAll: "Pharma/GetAllManufacturer",
  },

  Supplier: {
    Save: "Pharma/SaveSupplier",
    getAll: "Pharma/GetAllSuppliers",
  },

  Medicine: {
    Save: "Pharma/SaveMedicine",
    getAll: "Pharma/GetAllMedicine",
    uploadImage: "Pharma/UploadAttachment",
    UpdatePromoStatus: "Pharma/UpdatePromoStatus",
    UpdateMedicinSale: "Pharma/UpdateMedicinSale",
    UpdateMedicineStatus: "Pharma/UpdateMedicineStatus"
  },

  Request: {
    Save: "Request/Save",
    getAll: "Request/GetRequests",
    SaveNote: "Request/RequestNotesSave",
    getAllNotes: "Request/GetAllRequestNotes",
  },
  MedicineStock: {
    save: "Pharma/SaveMedicineStock",
    getAll: "Pharma/GetAllMedicineStock",
  },

  User: {
    UploadProfile: "User/UploadProfile",
  },
  Account: {
    UpdateStatus: "Account/UpdateStatus",
  },
  MedicineSale: {
    save: "Pharma/SaveMedicineSale",
    getAll: "Pharma/MedicineSaleById",
    getReceipt: "Pharma/",
  },
  Patient: {
    SavePatient: "Patient/SavePatient",
    Search: "Patient/Search",
  },
};

export const listType = {
  labsuperdata: "labsuperdata",
  clinicianavailability: "clinicianavailability",
  organization: "organization",
  medicalCertificates: "medicalCertificates",
  experience: "experience",
  request: "request",
  labProviders: "labProviders",
  labcategory: "labcategory",
  insuranceProviders: "insuranceProviders",
  insurancePlan:"insurancePlan",
  labTest: "labTest",
  labTestGroup: "labTestGroup",
  procedures: "procedures",
  proceduresdata:"proceduresdata",
  labsdata:"labsdata",
  pharmadata:"pharmadata",
  statusdata:"statusdata",
  patientvalue: "patientvalue",
  userAccess: "userAccess",
  manufacturar: "Manufacturar",
  supplier: "supplier",
  medicine: "medicine",
  portfolio: "portfolio",
  portfolioorg: "portfolioorg",
  wardd: "wardd",
  getward: "getward",
  inpatientAdmission: "inpatientAdmission",
  medicineData: "medicineData",
  forms: "forms",
  form: "form",
  formPack: "formPack",
  formPackForms: "formPackForms",
  clinician: "clinician",
  labclinician: "labclinician",
  availability: "availability",
  availabletime: "availabletime",
  labroles: "labroles",
  clinicianeducation: "clinicianeducation",
  stock: "stock",
  medicineSale: "medicineSale",
  servicePack: "servicePack",
  notifications: "notifications",
  appointments: "appointments",
  getappointservice:"getappointservice",
  getappointments: "getappointments",
  getapt: "getapt",
  labPackage: "labPackage",
  labappointment: "labappointment",
  specimendata: "specimendata",
  specimenforcolletion: "specimenforcolletion",
  specimencategory: "specimencategory",
  specimenbodysite: "specimenbodysite",
  ipvitals: "ipvitals"
};

export const login = {
  email: {
    required: { value: true, message: "Please Enter Valid Email" },
    pattern: {
      value: /^(?!.*[.]{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/,
      message: "Email Formate is required",
    },
  },
  password: { required: { value: true, message: "Please enter password" } },
};

export const addorganization = {
  organizationName: {
    required: {
      value: true,
      message: "please Enter Organization Name",
    },
  },
  email: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },
  phone: {
    required: {
      value: true,
      message: "Please Enter Mobile Number",
    },
  },
};
export const addpatient = {
  firstName: {
    required: {
      value: true,
      message: "please Enter Patient Name",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "please Enter Last Name",
    },
  },
  email: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },
  phone: {
    required: {
      value: true,
      message: "Please Enter Mobile Number",
    },
  },
  gender: {
    required: {
      value: true,
      message: "Please select gender"
    }
  },
  age: {
    required: {
      value: true,
      message: "Please enter age"
    }
  }
};
export const addPhysicianSchema = {
  fullName: {
    required: {
      value: true,
      message: "please Enter Full Name",
    },
  },
  resourseType: {
    required: {
      value: true,
      message: "please Select resourseType",
    },
  },
  email: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },
  phone: {
    required: {
      value: true,
      message: "Please Enter Mobile Number",
    },
  },
  dob: {
    required: {
      value: true,
      message: "Please Enter DOB",
    },
  },
  gender: {
    required: {
      value: true,
      message: "Please Select Gender",
    },
  },
  portfolioName: {
    required: {
      value: true,
      message: "Please Enter Portfolio Name",
    },
  },
};
export const setScheduless = {
  fullName: {
    required: {
      value: true,
      message: "please Enter Full Name",
    },
  },
  email: {
    required: {
      value: true,
      message: "please Enter Email",
    },
  },
  phone: {
    required: {
      value: true,
      message: "please Enter Phone Number",
    },
  },
  dob: {
    required: {
      value: true,
      message: "please Enter DOB",
    },
  },
  gender: {
    required: {
      value: true,
      message: "please Select Gender",
    },
  },
  portfolioName: {
    required: {
      value: true,
      message: "Please Enter Portfolio Name",
    },
  },
};
export const addportfolio = {
  portfolioName: {
    required: {
      value: true,
      message: "please Enter Portfolio Name",
    },
  },
  description: {
    required: {
      value: true,
      message: "Please Enter Description",
    },
  },
};
export const addward = {
  noOfBeds: {
    required: {
      value: true,
      message: "please Enter No Of Beds",
    },
  },
  wardName: {
    required: {
      value: true,
      message: "please Enter Ward Name",
    },
  },



  chargePerBed: {
    required: {
      value: true,
      message: "Please Enter Charge Per Bed",
    },
  },
  wardInCharge: {
    required: {
      value: true,
      message: "Please Enter Ward Incharge Name",
    },
  },
};

export const addprocedure = {
  procedureName: {
    required: {
      value: true,
      message: "please Enter No Of Beds",
    },
  },
  basicCharge: {
    required: {
      value: true,
      message: "please Enter Ward Name",
    },
  },
};
export const addform = {
  formPackName: {
    required: {
      value: true,
      message: "please Enter Form Pack Name",
    },
  },
  formName: {
    required: {
      value: true,
      message: "Please Select Form",
    },
  },
};
export const addserviceschema = {
  serviceName: {
    required: {
      value: true,
      message: "please Enter Service Name",
    },
  },
  description: {
    required: {
      value: true,
      message: "Please Enter Description",
    },
  },
  portfolioId: {
    required: {
      value: true,
      message: "Please Select Portfolio Name",
    },
  },
  serviceTime: {
    required: {
      value: true,
      message: "Please Enter Service Time",
    },
  },

  charge: {
    required: {
      value: true,
      message: "Please Enter Charge",
    },
  },
};
export const labProviderSchema = {
  providerName: {
    required: {
      value: true,
      message: "please Enter Provider Name",
    },
  },
  contactNumber: {
    required: {
      value: true,
      message: "Please Enter Mobile",
    },
  },
  contactAddress: {
    required: {
      value: true,
      message: "Please Enter Address",
    },
  },

};
export const labtestSchema = {
  providerId: {
    required: {
      value: true,
      message: "please Enter Provider Name",
    },
  },
  categoryId: {
    required: {
      value: true,
      message: "Please Enter Category Name",
    },
  },
  testName: {
    required: {
      value: true,
      message: "Please Enter Test Name",
    },
  },
  method: {
    required: {
      value: true,
      message: "Please Enter Method",
    },
  },
  preparationMethod: {
    required: {
      value: true,
      message: "Please Enter Preparation Method",
    }
  },
  // minValue: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Address",
  //   },
  // },
  // maxValue: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Address",
  //   },
  // },
  unit: {
    required: {
      value: true,
      message: "Please Enter Unit",
    },
  },
  interpretation: {
    required: {
      value: true,
      message: "Please Enter Interpretation",
    },
  },
  specimen: {
    required: {
      value: true,
      message: "please Enter Specimen Name",
    },
  },
  method: {
    required: {
      value: true,
      message: "Please Enter Method",
    },
  },
  isHomeCollection: {
    required: {
      value: true,
      message: "Please Select Is Home Collection",
    },
  },
  testPrice: {
    required: {
      value: true,
      message: "Please Enter Test Price",
    },
  },
  preRequistic: {
    required: {
      value: true,
      message: "please Enter Prerequistic",
    },
  },

};
export const testGroup = {

  testName: {
    required: {
      value: true,
      message: "Please Enter Test Group",
    },
  },
  testGroupName: {
    required: {
      value: true,
      message: "Please Enter Test Group Name",
    },
  }

};
export const labCatergorySchema = {
  categoryName: {
    required: {
      value: true,
      message: "please Enter Category Name",
    },
  },
  providerId: {
    required: {
      value: true,
      message: "Please Select Provider Name",
    },
  },
};
export const labSuperSchema = {
  labName: {
    required: {
      value: true,
      message: "please Enter Lab Name",
    },
  },

};

export const insuranceProviderSchema = {
  providerName: {
    required: {
      value: true,
      message: "please Enter  Name",
    },
  },

};


export const ipvitalsSchema = {
  height: {
    required: {
      value: true,
      message: "please Enter Height",
    },
  },
  weight: {
    required: {
      value: true,
      message: "Please Enter Weight",
    },
  },

  bmi: {
    required: {
      value: true,
      message: "Please Enter BMI",
    },
  },
  temperature: {
    required: {
      value: true,
      message: "Please Enter BMI",
    },
  },

  systolic: {
    required: {
      value: true,
      message: "Please Enter BMI",
    },
  },

  diastolic: {
    required: {
      value: true,
      message: "Please Enter BMI",
    },
  },


  respiratoryRate: {
    required: {
      value: true,
      message: "Please Enter RESP",
    },
  },

  pulseRate: {
    required: {
      value: true,
      message: "Please Enter PULS",
    },
  },
};

export const addRequest = {
  description: { required: { value: true, message: "Please Enter Description" } },
};

export const Notes = {
  note: { required: { value: true, message: "Please Enter Note" } },
};

export const AddManufacturars = {
  manufacturerName: { required: { value: true, message: "Please Enter Manufacturer Name" } },
  address: { required: { value: true, message: "Please Enter Address" } },
};

export const AddSupplier = {
  supplierName: { required: { value: true, message: "Please Enter Supplier Name" } },
  address: { required: { value: true, message: "Please Enter Address" } },
  contactPersonName: { required: { value: true, message: "Please Enter Contact Person Name" } },
  contactNumber: { required: { value: true, message: "Please Enter Contact Number" } },
};
export const meetingReason = {
  cancelationReason: { required: { value: true, message: "Please Enter Manufacturer Name" } },
};
export const addMeeting = {
  meetingName: { required: { value: true, message: "Please Enter Manufacturer Name" } },
  participateIds: { required: { value: true, message: "Please Enter Address" } },
};
export const AddMedicines = {
  manufacturerId: { required: { value: true, message: "Please Enter Manufacturer Name" } },
  // supplierId: { required: { value: true, message: "Please Enter First Name" } },
  medicineName: { required: { value: true, message: "Please Enter Medicine Name" } },
  genericName: { required: { value: true, message: "Please Enter Generic Name" } },
  doseForm: { required: { value: true, message: "Please Enter Dose Form" } },
  // substitutes: { required: { value: true, message: "Please Enter First Name" } },
  medicineIngredients: {
    isArray: { value: true, message: "Required" },
    innerSchema: {
      ingredientName: { required: { value: true, message: "Please Enter Ingredient Name" } },
      strength: { required: { value: true, message: "Please Enter Strength" } },
    },
  },
  reorderLevel: { required: { value: true, message: "Please Enter Recorder Level" } },
  definition: { required: { value: true, message: "Please Enter Definition" } },
};
export const BookingAppointment = {
  firstName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Please Enter Last Name",
    },
  },
  mobile: {
    required: {
      value: true,
      message: "Please Enter Mobile Number",
    },
    minlength: {
      value: 10,
    },
  },
};

export const addStock = {
  medicineId: { required: { value: true, message: "Please Enter Medicine Name" } },
  supplierId: { required: { value: true, message: "Please Enter Supplier Name" } },
  batchNo: { required: { value: true, message: "Please Enter Batch No" } },
  stockReceivedDate: { required: { value: true, message: "Please Enter Stock Received Date" } },
  quantity: { required: { value: true, message: "Please Enter Quantity" } },
  expiryDate: { required: { value: true, message: "Please Enter Expiry Date" } },
  purchasePrice: { required: { value: true, message: "Please Enter Purchase Price" } },
  mrpPrice: { required: { value: true, message: "Please Enter MRP Price" } },
  sellingPrice: { required: { value: true, message: "Please Enter Selling Price" } },
  unitsPerStrip: { required: { value: true, message: "Please Enter Units Per Strip" } },
};

export const addMedicineSAle = {
  customerName: { required: { value: true, message: "Please Enter Customer Name" } },
};


export const addInsurancePlan = {
  planName: { required: { value: true, message: "Please Enter Plan Name" } },
};



const sessionExpired = (istokenExpired) => {
  if (istokenExpired == true) {
    window.location.pathname = '/sessionexpired'
  }
}

export const notify = (status, msg) => {
  const toastOptions = {
    position: "bottom-left", // Set the position to bottom-right
    autoClose: 3000, // Close the toast after 3 seconds (adjust as needed)
    hideProgressBar: false, // Show the progress bar
    closeOnClick: true, // Close the toast when clicked
    pauseOnHover: true, // Pause the timer when hovering
    draggable: true, // Make the toast draggable
    progress: undefined, // Use the default progress bar
    // transition: Flip,
    theme: "dark",
    transition: Zoom,
    style: {
      width: "300px", // Adjust width as needed
    },
  };
  if (status == true) toast.success(msg, toastOptions);
  else toast.error(msg, toastOptions);
};

export const dilogueBox = ({ text, onClickFn, html }) => {
  Swal.fire({
    title: text,
    html: html,
    showCancelButton: true,
    confirmButtonText: "Confirm",
  }).then((result) => {
    if (result.isConfirmed) onClickFn();
  });
};
export const dilogueBox2 = (text, onClickFn, label, reason) => {
  Swal.fire({
    title: text,

    showCancelButton: true,
    confirmButtonText: "Yes",
    inputLabel: label, // Label for the input field
    inputPlaceholder: label,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      const inputValue = result.value; // Get the value entered in the input field
      onClickFn(inputValue);
    }
  });
};

export const getList = async (url, data) => {
  try {
    let res = await instance.post(url, { jsonString: data });
    sessionExpired(res?.data?.istokenExpired)
    return res?.data?.status == true && Array.isArray(res?.data?.data) && res?.data?.data?.length > 0
      ? res.data.data
      : [];
  } catch (e) {
    return [];
  }
};

export const getfList = async (url) => {
  try {
    let res = await instance.get(url);
    sessionExpired(res?.data?.istokenExpired)
    return res?.data?.status == true && Array.isArray(res?.data?.data) && res?.data?.data?.length > 0
      ? res.data.data
      : [];
  } catch (e) {
    return [];
  }
};

export const getById = async (url, data) => {
  try {
    let res = await instance.post(url, { jsonString: data });
    sessionExpired(res?.data?.istokenExpired)
    return res?.data?.status == true && Object.keys(res?.data?.data)?.length > 0 ? res?.data?.data : {};
  } catch (e) {
    return {};
  }
};

export const getByIdWithStatus = async (url, data) => {
  try {
    let res = await instance.post(url, { jsonString: data });

    if (res?.data?.status === true) {
      return res.data; // Return the full response structure
    } else {
      return res.data || { status: false, data: null, message: "Failed to fetch data" };
    }
  } catch (error) {
    console.error("Error in getByIdWithStatus:", error);
    return { status: false, data: null, message: "Failed to fetch data due to an error" };
  }
};

export const uploadFile = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    notify(res?.data?.status, res?.data?.message);
    return res?.data?.status == true ? res : {};
  } catch (e) {
    return {};
  }
};

export const save = async (url, data) => {
  try {
    let res = await instance.post(url, { jsonString: data });
    sessionExpired(res?.data?.istokenExpired)
    notify(res?.data?.status, res?.data?.message);
    return res?.data?.status == true ? res : {};
  } catch (e) {
    return {};
  }
};
export const saveFormData = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    notify(res?.data?.status, res?.data?.message);
    return res?.data?.status == true ? res : {};
  } catch (e) {
    return {};
  }
};
export const botResponce = async (url, data) => {
  try {
    let res = await botInstance.post(url, data);
    return res;
  } catch (e) {
    console.log(e);
    // return {};
  }
};
export const saveWithoutMessage = async (url, data) => {
  try {
    let res = await instance.post(url, { jsonString: data });
    sessionExpired(res?.data?.istokenExpired)
    if (res?.data?.status != true) notify(res?.data?.status, res?.data?.message);
    return res?.data?.status == true ? res : {};
  } catch (e) {
    return {};
  }
};

export const logOutFunction = () => (sessionStorage.clear(), (window.location.pathname = "/"));
export const getUserfromSS = () => JSON.parse?.(dec?.(sessionStorage.getItem("user")) || "{}");
export const getUniqueByKey = (array, key) =>
  [...new Map(array.map((item) => [key ? item[key] : item, item])).values()] || [];
export const settingdata = (data, keys) =>
  keys.reduce((temp, key) => Object.assign(temp, { [key]: data[key] || "" }), {});
export const resettingdata = (keys) => keys.reduce((temp, key) => Object.assign(temp, { [key]: "" }), {});
export const sortingList = (array, key, type) =>
  array.sort((a, b) =>
    type == "Number"
      ? a?.[key] - b?.[key]
      : type == "Date"
        ? new Date(a?.[key]) - new Date(b?.[key])
        : a?.[key]?.localeCompare(b?.[key])
  );
export const stringToInt = (data, keys) =>
  keys.reduce(
    (temp, key) => Object.assign(temp, { [key]: returningValue(data[key]) == "" ? 0 : parseInt(data[key], 10) }),
    data
  );
export const stringToArray = (str, dataArray, idKey) =>
  (str?.length > 0 && str?.split(",")?.map((e) => ({ ...dataArray?.find((v) => v?.[idKey] == e) }))) || [];

export const removeDuplicates = (array, key) => {
  const uniqueKeys = new Set();
  return array.filter((e) => (!uniqueKeys.has(e?.[key]) ? (uniqueKeys.add(e?.[key]), true) : false));
};

export const encryptData = (data) => {
  let en = CryptoJS.AES.encrypt(data?.toString(), "AROHS")?.toString();
  let encoded = CryptoJS.enc.Base64.parse(en).toString(CryptoJS.enc.Hex);
  return encoded;
};

export const enc = (data) => {
  const key = CryptoJS.enc.Utf8.parse(encryptKey);
  const iv = CryptoJS.enc.Utf8.parse(ivKey);

  if (returningValue(data, "") != "") {
    const encrypteddata = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })?.toString();
    const encrypted = encrypteddata.toString();
    return encrypted;
  } else {
    return null;
  }
};

// export const dec = (data) => {
//     const key = CryptoJS.enc.Utf8.parse(encryptKey);
//     const iv = CryptoJS.enc.Utf8.parse(ivKey);

//     if (returningValue(data, "") != '') {
//         const decryptedBytes = CryptoJS.AES.decrypt(data, key, {
//             keySize: 128 / 8,
//             iv: iv,
//             mode: CryptoJS.mode.CBC,
//             padding: CryptoJS.pad.Pkcs7
//         });
//         const decrypted = decryptedBytes.toString(CryptoJS.enc.Utf8);
//         return decrypted || ""
//     }
// }
export const dec = (data) => {
  const key = CryptoJS.enc.Utf8.parse(encryptKey);
  const iv = CryptoJS.enc.Utf8.parse(ivKey);

  if (returningValue(data, "") != "") {
    const decryptedBytes = CryptoJS.AES.decrypt(data, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decrypted = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decrypted || "";
  }
};

export const decryptData = (encoded) => {
  let temp = {};
  Object.entries(encoded)?.map(([key, value], i) => {
    if (i > 0) {
      let decoded1 = CryptoJS.enc.Hex.parse(value).toString(CryptoJS.enc.Base64);
      let decrypted1 = CryptoJS.AES.decrypt(decoded1, "AROHS")?.toString(CryptoJS.enc.Utf8);
      temp[key] = decrypted1;
    }
  });
  return temp;
};
export const handleDownload = (url, file, fileName) => {
  fetch(`${url}${file}/${fileName}`)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((error) => console.error("Error downloading file:", error));
};
export const sortingLists = (array, key) => {
  return array.sort((a, b) => (a?.[key] > b?.[key] ? 1 : a?.[key] < b?.[key] ? -1 : 0));
};
export const returningValue = (v, type) => {
  const isValid = v !== undefined && v !== null && v !== "" && v !== NaN && v !== "null" && v != "Invalid date";
  if (isValid) {
    switch (type) {
      case "Alphabet":
        var va = v
          ?.replace(/[^a-zA-Z ]/g, "")
          ?.replace(/^\s+/, "")
          ?.replace(/\s+/g, " ");
        va = va?.charAt(0).toUpperCase() + v.slice(1);
        return va;
      case "AlphaNumaric":
        var va = v
          ?.replace(/[^a-zA-Z0-9 ]/g, "")
          ?.replace(/^\s+/, "")
          ?.replace(/\s+/g, " ");
        va = va?.charAt(0)?.toUpperCase() + v?.slice(1);
        return va;
      case "Phone":
        let n = v
          ?.replace("+966", "")
          ?.replace(/[^0-9]/g, "")
          ?.replace(/\s+/g, "");
        let l = n?.length;
        if (l > 0 && l <= 2) return n?.replace(/(\d{1})/, "+966 $1", "");
        else if (l > 2 && l <= 5) return n?.replace(/(\d{2})(\d{1})/, "+966 $1 $2", "");
        else if (l > 5 && l <= 9) return n?.replace(/(\d{2})(\d{3})(\d{1})/, "+966 $1 $2 $3", "");
        break;
      case "Date":
        return new Date(v) !== "Invalid Date" ? new Date(v) : "";
      case "Email":
        return v?.toLowerCase()?.replace(/\s+/g, "");
      case "Number":
        return typeof v == "string" ? v?.replace(/[^0-9 ]/g, "") : v;
      case "Decimal":
        return parseFloat(v)?.toFixed(2);
      case "DateTime":
        return v === "Invalid date" ? "-" : v;
      case "LocalTime":
        return moment.utc(v, "YYYY-MM-DDTHH:mm:ss").local().format("YYYY-MMM-DD H:mm:ss");
      case "NumberString":
        return v?.replace(/[^0-9]/g, "");
      case "INR":
        va = typeof v == "string" ? parseInt(v) : v;
        return va.toLocaleString("en-IN", { style: "currency", currency: "INR" });
      case "WithoutSpaces":
        return v?.toString();
      case "ErrorColor":
        return "border border-danger";
      default:
        return v;
    }
  } else {
    switch (type) {
      case "Array":
        return [];
      case "Bool":
        return false;
      default:
        return "";
    }
  }
};

export const decryPtiedLinks = () => {
  let links = sessionStorage.getItem("accessLinks");
  if (links && links != undefined && links != "") {
    return JSON.parse(links);
  } else {
    return [];
  }
};

export const CheckLinks = (linkNames) => decryPtiedLinks()?.some((v) => linkNames?.some((a) => a == v));

export const navigationFn = (user) => {
  let roleId = user?.roleId;
  let firstTimeLog =
    user?.organizationStatus == "Approve" &&
    user?.accountStatus == "Active" &&
    user?.organizationAccountStatus == "Active" &&
    (user?.roleId == 4 ? user?.status == "Approve" : true);
  let route = "";
  console.log(firstTimeLog, "fir", user);
  if (roleId == 1) route = "/v1/organization/all-organization";
  else if (roleId == 2) route = "/v1/service/patientdashboard";
  else {
    if (firstTimeLog == true) {
      if (roleId == 3) route = "/v1/service/dashboard";
      else if (roleId == 4) route = "/v1/service/dashboard";
      else if (roleId == 5) route = "/pharma";
      else if ([6, 7, 8]?.some((v) => v == roleId)) route = "/v1/lab/lab-appointments/all-labAppointments";
    } else {
      if (roleId == 3) route = "/enter-org-details";
      else if (roleId == 4) route = "/physician-registration";
      else if (roleId == 5) route = "/pharma";
      else if ([6, 7, 8]?.some((v) => v == roleId)) route = "/physician-registration";
    }
  }

  // if (roleId == 1) {
  //     route = "/v1/organization/all-organization";
  // } else if (roleId == 3 && firstTimeLog) {
  //     route = "/enter-org-details";
  // } else if (roleId == 3 && !firstTimeLog) {
  //     route = "/v1/service/dashboard";
  // } else if (roleId == 4 && firstTimeLog) {
  //     route = "/physician-registration";
  // } else if (roleId == 4 && !firstTimeLog) {
  //     route = "/v1/service/dashboard";
  // } else if (roleId == 6 || roleId == 7 || roleId == 8 && firstTimeLog) {
  //     route = "/v1/service/physician/physician-set-availability";
  // } else if (roleId == 6 || roleId == 7 || roleId == 8 && firstTimeLog) {
  //     route = "/physician-registration";
  // } else if ((roleId == 6 || roleId == 7 || roleId == 8) && !firstTimeLog) {
  //     route = "/v1/service/dashboard";
  // } else if (roleId == 5) {
  //     route = "/v1/service/patientdashboard";
  // } else if (roleId == 13) {
  //     let credStatus = user?.credStatus;
  //     if (credStatus == 3 || credStatus == 4 || credStatus == 6) {
  //         route = "/v1/service/physiciandashboard";
  //     } else {
  //         if (user?.termsCondStatus == "Accepted") {
  //             route = `/cred/welcomecred/${enc(user?.userId)}`;
  //         } else {
  //             route = "/cred/termsandconditons";
  //         }
  //     }
  // } else if (roleId == 10) {
  //     route = "/v1/payroll/employee/all-employees";
  // }

  return route;
};

export const logOut = () => {
  sessionStorage.clear();
  window.location.pathname = "/";
};

export const filterList = (array, filterKeys, searchValue) =>
  array?.filter((v) =>
    filterKeys.some((a) =>
      (typeof v[a] == "string" ? v[a]?.toLowerCase() : v[a]?.toString())
        ?.split(" ")
        ?.join("")
        ?.includes(searchValue?.toLowerCase()?.split(" ")?.join(""))
    )
  );
export const exportExcel = (data, name) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(data);
  const columnWidths = data.reduce((acc, row) => {
    row.forEach((cell, i) => {
      acc[i] = Math.max(acc[i] || 0, String(cell).length + 2);
    });
    return acc;
  }, []);
  ws["!cols"] = columnWidths.map((width) => ({ width }));
  XLSX.utils.book_append_sheet(wb, ws, name);
  XLSX.writeFile(wb, `${name}.xlsx`);
};
export const returnStatusColor = (status) => {
  switch (status) {
    case "Requested":
      return css.Request;
    case "Booked":
      return css.Booked;
    case "Consulted":
      return css.Preadmission;
    case "Collected":
      return css.Collected;

    case "In Lab":
      return css.Complete;
    case "Admitted":
      return css.Admitted;
    case "InSurgery":
      return css.Booked;
    case "Discharge":
      return css.Preadmission;
    case "Completed":
      return css.Complete;
    case "WaitList": return css.pending
    case "Cancelled": return css.red
  }
};

export const mobileDetect = () => {
  const agent = window.navigator.userAgent;
  const deviceWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const IsIPhone = agent.includes("iPhone");
  const IsIPad = agent.includes("iPad") || (IsIPhone && deviceWidth > 750);
  if (IsIPad) {
    IsIPhone = false;
  }
  const macApp = agent.includes("Macintosh");
  if (macApp) {
    const canvas = document.createElement("canvas");
    if (canvas) {
      const context = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
      if (context) {
        const info = context.getExtension("WEBGL_debug_renderer_info");
        if (info) {
          const renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
          if (renderer.includes("Apple")) {
            IsIPad = true;
          }
        }
      }
    }
  }

  const IsIOSApp = IsIPad || IsIPhone;
  const IsAndroid = agent.includes("Android");
  const IsAndroidPhone = IsAndroid && deviceWidth <= 960;
  const IsAndroidTablet = IsAndroid && !IsAndroidPhone;

  let message = "";
  if (IsIPhone) message = "Device is iPhone";
  else if (IsIPad) message = "Device is iPad";
  else if (IsAndroidTablet || IsAndroidPhone || IsAndroid) message = "Device is Android";
  else message = "Device is Mac || Windows Desktop";
  return {
    message,
    isMobile: IsIOSApp || IsAndroid || IsAndroidTablet || IsAndroidPhone,
  };
};
