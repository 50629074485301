import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AROHSPrivateRoute from "./Components/PrivateRoutes/AROHSPrivateRoute";
import "./App.css";
import { getUserfromSS, logOut, notify } from "./ConfigFiles/Config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UnAuthorisedAccess from "./PrivateRoutes/UnAuthorisedAccess";
const RoutingConfig = lazy(() => import("./Components/RoutingConfig"));
const Login = lazy(() => import("./Components/Auth/Login"));
const LandingPage = lazy(() => import("./Components/Auth/LandingPage"));
const ClinicLogin = lazy(() => import("./Components/Auth/ClinicLogin"));
const PhysicianLogin = lazy(() => import("./Components/Auth/PhysicianLogin"));
const PhysicianRegistration = lazy(()=>import("./Components/PracticeManagement/Main/Masters/Physician/PhysicianOnBoarding/PhysicianRegistration"))
const EnterOrgDetails = lazy(()=>import("./Components/Organization/EnterOrgDetails"))
const OrgDetailsFinalSubmit=lazy(()=>import("./Components/Organization/OrgDetailsFinalSubmit"))

const App = () => {
  
 
   
  return (
    <div>
      <div >
        <Suspense fallback={() => <div>Loading ....</div>}>
          <ToastContainer />
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              {/* <Route path="" element={<LandingPage />} /> */}
              <Route path="*" element={<Login />} />
              <Route path="/register-clinician" element={<ClinicLogin />} />
              <Route path="/physician-login" element={<PhysicianLogin />} />
              <Route path="physician-registration" element={<PhysicianRegistration/>}/>
              <Route path="sessionexpired" element={<UnAuthorisedAccess />} />
             <Route path="enter-org-details" element={<EnterOrgDetails/>}/> 
             <Route path="submission-confirmation" element={<OrgDetailsFinalSubmit/>}/> 

              <Route
                path="v1/*"
                element={
                  <AROHSPrivateRoute>
                    <RoutingConfig />
                  </AROHSPrivateRoute>
                }
              />
            </Routes>
          </Router>
        </Suspense>
      </div>
    </div>
  );
};

export default App;
