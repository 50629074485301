import React, { useEffect, useState } from "react";
import Loginimg from "../../Images/loginimg.png";
import anatrxlogo from "../../Images/loginanatxlogo.png";
import tag from "../../Images/tag.png";
import star from "../../Images/Star 3.png";
import stars from "../../Images/Star 5.png";
import heart from "../../Images/heart.png";
import { useFormValidation } from "../../Validations/useFormValidations";
import { enc, getList, navigationFn, notify, returningValue, save, saveWithoutMessage, urls } from "../../ConfigFiles/Config";
import { login } from "../../ConfigFiles/Config";
import { ConfigFunctions } from "../../ConfigFiles/ConfigFunctions";
import { CiRead, CiUnread } from "react-icons/ci";

const Login = () => {
  const { navigate, checkLoginStatus } = ConfigFunctions();
  const [password, setPassword] = useState(true);
  const changingPasswordtype = () => setPassword(!password);

  const submit = async () => {
    let res = await saveWithoutMessage(urls?.auth?.signIn, data);
    if (res?.data?.status == true) {
      console.log(res?.data,"sdfghjkl;")
      let roleId = res?.data?.data?.user?.roleId;
      notify(res?.data?.status, res?.data?.message);
      sessionStorage.setItem("roleId", res?.data?.data[0]?.roleId);
      sessionStorage.setItem("firstTimeLog", res?.data?.data[0]?.firstLog);

      sessionStorage.setItem("token", res?.data?.token);
      sessionStorage.setItem("user", enc(JSON.stringify(res?.data?.data[0])));
       let res1 = await getList(urls.UserAccess.GetAccessLinks, { userId: res?.data?.data[0]?.userId,roleId:res?.data?.data[0]?.roleId });
      let links = res1?.filter((v) => v?.status == "Active")?.map((e) => e.linkName);
      sessionStorage.setItem("accessLinks", JSON.stringify(links));     
       let route = navigationFn(res?.data?.data[0]);
      navigate(route);
    }
  };

  const { errors, data, formChange, handleChange, handleSubmit } = useFormValidation({
    initialValues: {},
    validationSchema: login,
    submit: submit,
  });

  useEffect(() => {
    if (checkLoginStatus()) {
      let route = navigationFn(JSON.parse(sessionStorage.getItem("user")));
      navigate(route);
    }
  }, []);

  useEffect(()=>{
    sessionStorage.clear()
  },[])
  return (
    <div className="d-flex flex-column flex-md-row vh-100">
      <div className="col-12 col-md-6 d-flex flex-column align-items-center justify-content-center text-center p-2">
        <img src={anatrxlogo} alt="Anatrx Logo" height={150} className="" />
        <h3 className="fw-bold">Welcome Back</h3>
        <p style={{ marginBottom: "10px" }} className="text-muted">
          Start your journey login into your account
        </p>
        <div style={{ width: "100%", maxWidth: "320px" }} className="d-flex flex-column align-items-center mx-2">
          <div className="form-groups w-100 text-start">
            <label htmlFor="email" className="" style={{ marginBottom: "5px", fontWeight: "bold" }}>
              Email
            </label>
            <input
              type="text"
              value={returningValue(data?.["email"], "")}
              onChange={formChange("")}
              name="email"
              className={`form-control  ${returningValue(errors?.email, "ErrorColor")}`}
              placeholder="Email"
            />
          </div>
          <div className="form-groups mt-3 w-100 text-start" style={{ position: "relative" }}>
            <label htmlFor="password" className="" style={{ marginBottom: "5px", fontWeight: "bold" }}>
              Password
            </label>
            <input
              type={password ? "password" : "text"}
              name="password"
              value={returningValue(data?.["password"], "")}
              onChange={formChange("")}
              className={`form-control ${returningValue(errors?.password, "ErrorColor")}`}
              id="floatingPassword"
              placeholder="Password"
              style={{ paddingRight: "40px" }}
            />
            <a
              className="passwordshowbtn "
              style={{ position: "absolute", bottom: "8px", right: "10px", cursor: "pointer" }}
              onClick={changingPasswordtype}
            >
              {password ? <CiUnread size={"20px"} color="black" /> : <CiRead size={"20px"} color="black" />}
            </a>
          </div>

          <div className="d-flex justify-content-between mt-2 w-100">
            <div>
              <input type="checkbox" id="remember" />
              <label htmlFor="remember" className="ms-1">
                Remember
              </label>
            </div>
            <a href="#">Forget Password</a>
           
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="mt-3 w-100 btn btn  text-white"
            style={{ backgroundColor: "#154360" }}
          >
            Sign in
          </button>
        </div>
      </div>
      <div className="col-12 col-md-6 loginimage">
        <img src={Loginimg} width="100%" height="100%" />

        <div
          className="d-flex justify-content-center align-items-around p-2 gap-1"
          style={{ position: "relative", bottom: "160px" }}
        >
          <div className="bg-white p-2 d-flex gap-1" style={{ borderRadius: "30px", width: "240px", height: "70px" }}>
            <div className="mt-1">
              <img src={tag} width={40} height={40} />
            </div>
            <div>
              <span className="fw-bold">100+ Customers</span>
              <p className="text-muted text-center">Trusted World Wide</p>
            </div>
          </div>
          <div
            className="bg-white p-2 d-flex align-items-center justify-content-center p-2"
            style={{ borderRadius: "30px", width: "240px", height: "70px" }}
          >
            <div>
              <div className="mt-3 d-flex">
                <strong> 4.0</strong>
                <img src={star} width={25} height={25} />
                <img src={star} width={25} height={25} />
                <img src={star} width={25} height={25} />
                <img src={star} width={25} height={25} />
                <img src={stars} width={25} height={25} />
              </div>
              <p className=" text-center">Star Average Rating</p>
            </div>
          </div>

          <div className="bg-white p-1 d-flex gap-1" style={{ borderRadius: "30px", width: "260px", height: "70px" }}>
            <div className="mt-1">
              <img src={heart} width={40} height={40} />
            </div>
            <div>
              <span className="fw-bold">200+ Patients</span>
              <p className="text-muted">Helped from Health Risk</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
