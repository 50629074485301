import { useState, useEffect, useRef } from "react";
import { RxTrackPrevious, RxTrackNext } from "react-icons/rx";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import "../App.css";
import { css } from "../ConfigFiles/Config";

const ResponsiveTable = ({ columns, dataTable, filyterKeys, dataState, showSearch, filters, goTo,lastKeys }) => {
  const firstRowRef = useRef(null);
  const [columns1, setColumns1] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
   const filterFunction = (fil) => {
    const lowerCaseFilter = fil.toLowerCase();
    const filteredData = dataTable.filter((e) =>
      filyterKeys?.some((key) => {
        const value = e[key]?.toString()?.toLowerCase() || "";
        return value.includes(lowerCaseFilter);
      })
    );
  
    setFilterValues(filteredData);
    setCurrentPage(1);
  };
  
 const sorting = (item) => {
   if (item?.sortable == true) {
     let key = item?.key;
     let d = [...dataTable];
     item?.sorted == true
       ? d.sort((a, b) =>
         item?.type == "Number"
           ? b?.[key] - a?.[key]
           : item?.type == "Date"
             ? new Date(b?.[key]) - new Date(a?.[key])
             : b?.[key]?.localeCompare(a?.[key])
       )
       : d.sort((a, b) =>
         item?.type == "Number"
           ? a?.[key] - b?.[key]
           : item?.type == "Date"
             ? new Date(a?.[key]) - new Date(b?.[key])
             : a?.[key]?.localeCompare(b?.[key])
       );
     setFilterValues(d);
     setCurrentPage(1);
     let c = columns1?.map((e) => ({ ...e, sorted: item == e ? !e?.sorted : false }));
     setColumns1(c);
   }
 };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const lastPage = Math.ceil(filterValues.length / postsPerPage);

  const updateCurrentPosts = () => {
    setCurrentPosts(filterValues.slice(indexOfFirstPost, indexOfLastPost));
  };

  useEffect(() => {
    updateCurrentPosts();
    firstRowRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [currentPage, postsPerPage, dataTable, filterValues]);

  useEffect(() => {
    setFilterValues(dataTable);
  }, [dataTable]);

  useEffect(() => {
    setColumns1(columns.map((e) => ({ ...e, sorted: false })));
  }, [columns]);

  return (
    <div className="bg-white h-100 border rounded p-3">
      {showSearch&&<div className="col-md-12 d-flex position-absolut   mb-1 p-1">
        <div className="col-md-4">
        <input
          type="search"
          onChange={(e) => {
            filterFunction(e.target.value);
          }}
          className="form-control col-md-12 my-1 search-control search-bg"
          id
          placeholder="Search ..."
        />
        </div>
        <div className="ms-2">
        {filters}</div>
      </div>}

      <div style={{ height: "70vh", overflowY: "auto" }}>
        {currentPosts.length > 0 ? (
          <>
            {/* Desktop View - Table */}
            {!isMobile ? (
              <>
                <div className="d-flex justify-content-between text-white p-2" style={{ backgroundColor: css?.themeColor }}>
                  {columns1.map((e, index) => (
                    <div
                      className={`${e?.width || "col"} pointer fw-bold`}
                      key={index}
                      onClick={() => sorting(e)}
                    >
                      {e?.name}
                    </div>
                  ))}
                </div>
                <div style={{ height: "64vh", overflowY: "auto" }}>
                  {currentPosts.map((e, i) => (
                    <div
                      key={i}
                      className="d-flex justify-content-between p-3"
                      style={{ backgroundColor: i % 2 === 0 ? "" : "#f3faff" }}
                    >
                      {columns1.map((c, j) => (
                        <div
                          key={j}
                          className={`${c?.width || "col"}`}
                          // onClick={() => typeof goTo === "function" && goTo(e)}
                          onClick={()=>{
                            if (lastKeys ? !lastKeys.includes(j) : true){
                              typeof goTo === "function" && goTo(e)
                            }
                          }}
                        >
                          {c?.selector?.(e, i) || e[c?.key] || "-"}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              // Mobile View - Card Layout
              <>
                {currentPosts.map((e, i) => (
                  <div key={i} className="card p-2 my-2  ">
                    {columns1.map((c, j) => (
                      <div key={j} className="d-flex justify-content-between">
                        <strong>{c.name}:</strong>
                        <span className="ms-2">{c.selector?.(e, i) || e[c.key] || "-"}</span>
                        
                      </div>
                    ))}
                    <hr/>
                  </div>
                ))}
              
              </>
            )}
          </>
        ) : (
          <div className="text-center p-2">No Data Found</div>
        )}
      </div>

      {currentPosts.length > 0 && (
        <div className="col-md-12 d-flex align-items-center justify-content-center py-2 text-white" style={{ background: css?.themeColor }}>
          <div>
            {Math.min((currentPage - 1) * postsPerPage + 1, filterValues.length)} -{" "}
            {Math.min(currentPage * postsPerPage, filterValues.length)} of {filterValues.length}
          </div>
          <div className="d-flex col-md-2 justify-content-center align-items-center">
            <RxTrackPrevious size="2.3rem" className="cursor-pointer ms-1" onClick={() => setCurrentPage(1)} />
            <GrFormPrevious size="2.5rem" className="cursor-pointerms-1" onClick={() => setCurrentPage((p) => Math.max(1, p - 1))} />
            <select className="form-select ms-1" onChange={(e) => setPostsPerPage(parseInt(e.target.value))}>
              {[10, 20, 50, 100].map((e, i) => (
                <option key={i}>{e}</option>
              ))}
            </select>
            <span className="ms-1">{currentPage}</span>
            <GrFormNext size="2.5rem" className="cursor-pointer ms-1" onClick={() => setCurrentPage((p) => Math.min(lastPage, p + 1))} />
            <RxTrackNext size="2.3rem" className="cursor-pointer ms-1" onClick={() => setCurrentPage(lastPage)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ResponsiveTable;
